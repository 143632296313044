@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 3;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 1.5px black;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.navbar__linksBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1vw 0 2vw;
  background-image: linear-gradient(125deg, #252525, #313131);
}

.navbar__linksBox--links:hover {
  color: white;
  opacity: 1;
}

.navbar__linksBox--links {
  justify-content: flex-start;
  color: whitesmoke;
  text-decoration: none;
  opacity: 0.7;
}

.navbar__linksBox--links__backImg:hover {
  opacity: 1;
  -webkit-transform: scale(1.1) rotate(90deg);
          transform: scale(1.1) rotate(90deg);
}

.navbar__linksBox--links__backImg {
  height: 20px;
  padding: 0 0 0 5px;
  opacity: 0.5;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

@media (max-width: 576px) {
  .navbar__linksBox--links {
    padding: 15px;
  }
}

.navbar__linksBox--dropMenuBtn {
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: whitesmoke;
  outline: none;
}

.navbar__linksBox--dropMenuBtn__img:hover {
  opacity: 1;
  -webkit-transform: scale(1.1) rotate(90deg);
          transform: scale(1.1) rotate(90deg);
}

.navbar__linksBox--dropMenuBtn__img {
  height: 20px;
  padding: 5px 0 0 5px;
  opacity: 0.5;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.navbar__linksBox--dropMenuBtn__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  border: none;
  box-shadow: 1px 1px 1px gray;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .navbar__linksBox--dropMenuBtn__container {
    border-radius: 0 0 5px 5px;
    top: 100%;
    left: 83vw;
  }
}

@media (max-width: 1200px) {
  .navbar__linksBox--dropMenuBtn__container {
    border-radius: 0 0 5px 5px;
    top: 100%;
    left: 85vw;
  }
}

@media (max-width: 992px) {
  .navbar__linksBox--dropMenuBtn__container {
    top: 100%;
    left: 83vw;
  }
}

@media (max-width: 768px) {
  .navbar__linksBox--dropMenuBtn__container {
    top: 100%;
    left: 76vw;
  }
}

@media (max-width: 576px) {
  .navbar__linksBox--dropMenuBtn__container {
    border-radius: 0 0 3px 3px;
    top: 100%;
    left: 65vw;
  }
}

.navbar__linksBox--dropMenuBtn__container--button:hover {
  background-image: linear-gradient(to right, #242424, #313131);
  color: white;
  opacity: 0.8;
}

.navbar__linksBox--dropMenuBtn__container--button {
  text-align: start;
  border: none;
  cursor: pointer;
  background-color: whitesmoke;
}

@media (min-width: 1200px) {
  .navbar__linksBox--dropMenuBtn__container--button {
    font-size: 15px;
    padding: 10px;
    width: 15vw;
  }
}

@media (max-width: 1200px) {
  .navbar__linksBox--dropMenuBtn__container--button {
    font-size: 15px;
    padding: 8px;
    width: 13vw;
  }
}

@media (max-width: 992px) {
  .navbar__linksBox--dropMenuBtn__container--button {
    font-size: 15px;
    padding: 8px;
    width: 14vw;
  }
}

@media (max-width: 768px) {
  .navbar__linksBox--dropMenuBtn__container--button {
    width: 22vw;
  }
}

@media (max-width: 576px) {
  .navbar__linksBox--dropMenuBtn__container--button {
    font-size: 18px;
    width: 33vw;
  }
}
/*# sourceMappingURL=navbar.css.map */
.project1 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}

.project2 {
  -ms-grid-column: 3;
  grid-column: 3;
}

.project3 {
  -ms-grid-column: 1;
  grid-column: 1;
}

.project4 {
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-column: 2/4;
}

.main__pageBox {
  background-color: white;
  overflow: hidden;
  height: 100vh;
  padding: 4vh 0 25vh 0;
}

@media (max-width: 992px) {
  .main__pageBox {
    height: 260vh;
  }
}

@media (max-width: 768px) {
  .main__pageBox {
    height: 250vh;
  }
}

@media (max-width: 576px) {
  .main__pageBox {
    height: 130vh;
  }
}

.main__pageBox--header {
  text-align: center;
  color: rgba(42, 39, 46, 0.705);
  cursor: default;
}

.main__pageBox--projectsBox {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(300px, auto);
  grid-gap: 2px;
  padding: 5px;
}

@media (max-width: 768px) {
  .main__pageBox--projectsBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.main__pageBox--projectsBox__card {
  background-attachment: fixed;
  border: none;
  cursor: default;
  position: relative;
  overflow: hidden;
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card {
    border-radius: 0;
    margin: 10px 0;
    height: 240px;
  }
}

.main__pageBox--projectsBox__card--img {
  background-image: url(/static/media/code.4bffe307.jpg);
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
}

@media (min-width: 1200px) {
  .main__pageBox--projectsBox__card--img {
    height: 50vh;
  }
}

@media (max-width: 1200px) {
  .main__pageBox--projectsBox__card--img {
    height: 40vh;
    background-size: cover;
    background-position: top left;
  }
}

@media (max-width: 992px) {
  .main__pageBox--projectsBox__card--img {
    height: 62vh;
  }
}

@media (max-width: 768px) {
  .main__pageBox--projectsBox__card--img {
    height: 60vh;
  }
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card--img {
    height: 200px;
    background-size: cover;
  }
}

.main__pageBox--projectsBox__card--textBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(3px) invert(0.3);
          backdrop-filter: blur(3px) invert(0.3);
  box-shadow: 1px 1px 2px gray;
}

@media (max-width: 1200px) {
  .main__pageBox--projectsBox__card--textBox {
    box-shadow: none;
  }
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card--textBox {
    height: 40px;
  }
}

.main__pageBox--projectsBox__card--textBox__header:hover {
  background-color: rgba(31, 31, 31, 0.2);
  box-shadow: 5px 0 0 rgba(31, 31, 31, 0.2), -5px 0 0 rgba(31, 31, 31, 0.2);
}

@media (max-width: 1200px) {
  .main__pageBox--projectsBox__card--textBox__header:hover {
    box-shadow: none;
  }
}

.main__pageBox--projectsBox__card--textBox__header {
  text-decoration: none;
  font-weight: normal;
  color: white;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0 10px;
  box-shadow: 5px 0 0 rgba(255, 255, 255, 0.15), -5px 0 0 rgba(255, 255, 255, 0.15);
  border-radius: 2px;
  pointer-events: auto;
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card--textBox__header {
    font-size: 20px;
    box-shadow: none;
    border-radius: 7px;
  }
}

.main__pageBox--projectsBox__card--textBox__link:hover {
  background-color: rgba(31, 31, 31, 0.2);
  box-shadow: 5px 0 0 rgba(31, 31, 31, 0.2), -5px 0 0 rgba(31, 31, 31, 0.2);
}

@media (max-width: 1200px) {
  .main__pageBox--projectsBox__card--textBox__link:hover {
    box-shadow: none;
  }
}

.main__pageBox--projectsBox__card--textBox__link {
  text-decoration: none;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0 10px;
  box-shadow: 5px 0 0 rgba(255, 255, 255, 0.15), -5px 0 0 rgba(255, 255, 255, 0.15);
  border-radius: 2px;
  margin: 0;
  pointer-events: auto;
}

@media (max-width: 1200px) {
  .main__pageBox--projectsBox__card--textBox__link {
    box-shadow: none;
  }
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card--textBox__link {
    font-size: 20px;
    border-radius: 7px;
  }
}

.main__pageBox--projectsBox__card--textBox__subHeader {
  color: white;
  font-weight: normal;
  font-size: 24px;
  padding: 0 5px;
  margin: 0;
}

@media (max-width: 576px) {
  .main__pageBox--projectsBox__card--textBox__subHeader {
    font-size: 20px;
  }
}

#portfolioDemo {
  background-image: url(/static/media/github.3743280c.jpg);
}

#portfolioDemo:hover {
  background-image: url(/static/media/portfolio.727245dc.gif);
  -webkit-animation-play-state: initial;
          animation-play-state: running;
          animation-play-state: initial;
}

#bandDemo {
  background-image: url(/static/media/pgec-still.8baf4e1e.jpg);
  object-fit: cover;
}

#bandDemo:hover {
  background-image: url(/static/media/pgec.25db5c18.gif);
  -webkit-animation-play-state: initial;
          animation-play-state: running;
          animation-play-state: initial;
}

#brainFlixDemo {
  background-image: url(/static/media/brainflix-still.b98b8964.jpg);
  object-fit: cover;
}

#brainFlixDemo:hover {
  background-image: url(/static/media/brainflix.a13ef6c9.gif);
  -webkit-animation-play-state: initial;
          animation-play-state: running;
          animation-play-state: initial;
}

#ivgDemo {
  background-image: url(/static/media/ivg-still2.30eaaec3.jpg);
  object-fit: cover;
}

#ivgDemo:hover {
  background-image: url(/static/media/ivg2.7285ef03.gif);
  -webkit-animation-play-state: initial;
          animation-play-state: running;
          animation-play-state: initial;
}
/*# sourceMappingURL=projects.css.map */
.main__skillsBox {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: default;
  z-index: 2;
}

@media (min-width: 1200px) {
  .main__skillsBox {
    height: 100vh;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .main__skillsBox {
    height: 100vh;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .main__skillsBox {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .main__skillsBox {
    height: 200vh;
    align-items: center;
    flex-wrap: unset;
    background-color: whitesmoke;
  }
}

.main__skillsBox--background {
  position: absolute;
  height: 100vh;
  width: 100%;
}

@media (min-width: 1200px) {
  .main__skillsBox--background {
    opacity: 0.1;
  }
}

@media (max-width: 1200px) {
  .main__skillsBox--background {
    opacity: 0.1;
  }
}

.main__skillsBox--card:hover {
  -webkit-transform: scale(1.05) translateY(-5px);
          transform: scale(1.05) translateY(-5px);
}

.main__skillsBox--card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  -webkit-backdrop-filter: blur(3px) invert(0.3);
          backdrop-filter: blur(3px) invert(0.3);
  box-shadow: 2px 2px 3px grey;
  border-radius: 7px;
  margin: 10px;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  z-index: 2;
}

@media (min-width: 1200px) {
  .main__skillsBox--card {
    width: 180px;
    height: 250px;
  }
}

@media (max-width: 1200px) {
  .main__skillsBox--card {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .main__skillsBox--card {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .main__skillsBox--card {
    width: 95%;
    margin: 5px;
    justify-content: center;
  }
}

.main__skillsBox--card__img {
  width: 120px;
  background-color: transparent;
  padding: 30px 5px 5px 5px;
}

@media (max-width: 576px) {
  .main__skillsBox--card__img {
    padding: 0;
  }
}

.main__skillsBox--card__header {
  text-align: center;
  background-color: inherit;
  color: white;
  font-weight: bolder;
  background-color: transparent;
  padding: 5px;
}

@media (min-width: 1200px) {
  .main__skillsBox--card__header {
    font-size: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .main__skillsBox--card__header {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .main__skillsBox--card__header {
    font-weight: normal;
    font-size: 26px;
    margin: 0;
  }
}
/*# sourceMappingURL=skills.css.map */
.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main__goUp {
  z-index: 10;
  position: fixed;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in;
}

@media (min-width: 1200px) {
  .main__goUp {
    top: 90vh;
    left: 95vw;
  }
}

@media (max-width: 1200px) {
  .main__goUp {
    top: 90vh;
    left: 90vw;
  }
}

@media (max-width: 576px) {
  .main__goUp {
    top: 85vh;
    left: 88vw;
  }
}

.main__goUp--imgUp:hover {
  opacity: 0.95;
  -webkit-transform: scale(1.1) rotate(90deg);
          transform: scale(1.1) rotate(90deg);
}

.main__goUp--imgUp {
  cursor: pointer;
  opacity: 0.5;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (min-width: 1200px) {
  .main__goUp--imgUp {
    width: 40px;
    display: unset;
  }
}

@media (max-width: 1200px) {
  .main__goUp--imgUp {
    width: 35px;
  }
}

@media (max-width: 576px) {
  .main__goUp--imgUp {
    width: 35px;
  }
}

.main__splashBox {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.main__splashBox--background {
  background-image: linear-gradient(to bottom, rgba(18, 36, 54, 0.829), #c0c4d6), url(/static/media/abstract-background.e2893a28.gif);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

.main__splashBox--container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20vh 0;
}

.main__splashBox--container__header {
  color: white;
  font-weight: normal;
  cursor: default;
}

@media (min-width: 1200px) {
  .main__splashBox--container__header {
    font-size: 600%;
    margin: 0;
    padding: 2px;
  }
}

@media (max-width: 1200px) {
  .main__splashBox--container__header {
    font-size: 75px;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .main__splashBox--container__header {
    font-size: 65px;
  }
}

@media (max-width: 768px) {
  .main__splashBox--container__header {
    font-size: 50px;
  }
}

@media (max-width: 576px) {
  .main__splashBox--container__header {
    font-size: 32px;
    padding: 20px 5px 5px 5px;
    margin: 0 0 10px 0;
  }
}

.main__splashBox--container__subHeader {
  color: white;
  font-weight: normal;
  cursor: default;
}

@media (min-width: 1200px) {
  .main__splashBox--container__subHeader {
    font-size: 350%;
    margin: 10px 0 25vh 0;
    padding: 2px 7px;
  }
}

@media (max-width: 1200px) {
  .main__splashBox--container__subHeader {
    font-size: 50px;
    margin: 10px 0 25vh 0;
  }
}

@media (max-width: 992px) {
  .main__splashBox--container__subHeader {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .main__splashBox--container__subHeader {
    font-size: 35px;
  }
}

@media (max-width: 576px) {
  .main__splashBox--container__subHeader {
    font-size: 30px;
    padding: 0 0 30px 0;
  }
}

.main__splashBox--container__scrollDown--toProjects:hover {
  color: gray;
  border: white 2px solid;
  background-color: white;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.main__splashBox--container__scrollDown--toProjects {
  font-weight: normal;
  font-size: 20px;
  color: white;
  background-color: transparent;
  padding: 10px 20px;
  overflow: hidden;
  cursor: pointer;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  border-radius: 15px;
  border-top: solid 2px lightgrey;
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  border-left: solid 2px lightgrey;
}

.main__splashBox--container__scrollDown--img:hover {
  opacity: 0.9;
  -webkit-transform: scale(1.2) rotate(270deg);
          transform: scale(1.2) rotate(270deg);
}

.main__splashBox--container__scrollDown--img {
  cursor: pointer;
  opacity: 0.5;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

@media (min-width: 1200px) {
  .main__splashBox--container__scrollDown--img {
    width: 6vw;
  }
}

@media (max-width: 1200px) {
  .main__splashBox--container__scrollDown--img {
    width: 70px;
  }
}

@media (max-width: 576px) {
  .main__splashBox--container__scrollDown--img {
    width: 60px;
  }
}
/*# sourceMappingURL=main.css.map */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #252525;
  padding: 10vh 0 10vh 0;
}

.footer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__container--sloganBox {
  padding-top: 5vh;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.footer__container--sloganBox__logo {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 0;
  width: 40vw;
  height: 80px;
  object-fit: cover;
}

.footer__container--sloganBox__text {
  display: flex;
  background-color: transparent;
  color: white;
  margin-bottom: 0;
  width: 40vw;
  font-size: 14px;
}

.footer__container--contactBox {
  background-color: transparent;
  display: flex;
  justify-content: center;
  border-top: thin solid whitesmoke;
  width: 50%;
}

@media (max-width: 1200px) {
  .footer__container--contactBox {
    width: 700px;
  }
}

@media (max-width: 992px) {
  .footer__container--contactBox {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .footer__container--contactBox {
    width: 300px;
  }
}

.footer__container--contactBox__column {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 5px 20px 5px 20px;
  align-items: center;
}

.footer__container--contactBox__column--row:hover {
  text-shadow: 1.5px 1.5px 5px white;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.footer__container--contactBox__column--row {
  display: flex;
  background-color: transparent;
  padding: 5px;
  color: whitesmoke;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0.5px 0.25px grey;
  font-size: 14px;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.footer__container--linkBox {
  display: flex;
  justify-content: center;
  border-top: thin solid whitesmoke;
  width: 50%;
}

@media (max-width: 1200px) {
  .footer__container--linkBox {
    width: 700px;
  }
}

@media (max-width: 992px) {
  .footer__container--linkBox {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .footer__container--linkBox {
    width: 300px;
  }
}

.footer__container--linkBox__link {
  padding: 20px;
  text-decoration: none;
  color: whitesmoke;
  text-shadow: 0.5px 0.25px grey;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.footer__container--linkBox__link:hover {
  padding: 20px;
  text-decoration: none;
  text-shadow: 1.5px 1.5px 5px white;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.footer__container--signatureBox {
  position: absolute;
  left: 1%;
  z-index: 999;
  bottom: 0;
  display: none;
}

.footer__container--signatureBox__signature {
  color: white;
  font-size: 12px;
}
/*# sourceMappingURL=footer.css.map */
* {
  font-family: 'Montserrat', sans-serif;
}

* .scrolled {
  visibility: visible;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.longBackgroundContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(135deg, rgba(79, 71, 146, 0), rgba(32, 31, 36, 0.075));
  background-repeat: no-repeat;
}
/*# sourceMappingURL=styles.css.map */
.projectInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to top, rgba(250, 250, 250, 0.418), rgba(222, 211, 233, 0.418));
  background-size: cover;
}

@media (min-width: 1200px) {
  .projectInfo {
    height: 110vh;
  }
}

@media (max-width: 1200px) {
  .projectInfo {
    height: 110vh;
  }
}

@media (max-width: 992px) {
  .projectInfo {
    height: 125vh;
  }
}

@media (max-width: 768px) {
  .projectInfo {
    height: 125vh;
  }
}

@media (max-width: 576px) {
  .projectInfo {
    height: 140vh;
  }
}

.projectInfo__linkBox {
  overflow: hidden;
}

.projectInfo__linkBox--link {
  cursor: default;
  overflow: inherit;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.projectInfo__linkBox--link__img {
  background-image: url(/static/media/code.4bffe307.jpg);
  object-fit: cover;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .projectInfo__linkBox--link__img {
    height: 98vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 1200px) {
  .projectInfo__linkBox--link__img {
    height: 600px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
  }
}

@media (max-width: 992px) {
  .projectInfo__linkBox--link__img {
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .projectInfo__linkBox--link__img {
    height: 400px;
  }
}

@media (max-width: 576px) {
  .projectInfo__linkBox--link__img {
    height: 300px;
    background-size: 600px;
  }
}

.projectInfo__linkBox--link__header {
  position: absolute;
  left: 1%;
  top: 5%;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px grey;
}

.projectInfo__textSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1vw;
}

@media (max-width: 992px) {
  .projectInfo__textSection {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .projectInfo__textSection--row {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .projectInfo__textSection--row {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .projectInfo__textSection--row {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .projectInfo__textSection--row {
    padding: 0 0 10vh 0;
  }
}

.projectInfo__textSection--row__text {
  text-shadow: 0.5px 0.25px grey;
}

.projectInfo__textSection--row__link:hover {
  color: white;
  box-shadow: 10px 0 0 orangered, -10px 0 0 orangered;
  background-color: orangered;
}

.projectInfo__textSection--row__link {
  font-weight: bold;
  color: black;
  text-shadow: 0.5px 0.25px grey;
  text-decoration: none;
  box-shadow: 10px 0 0 orange, -10px 0 0 orange;
  background-color: orange;
}

.projectInfo__textSection--imgBox__img {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .projectInfo__textSection--imgBox__img {
    width: 80px;
    margin: 0 5px;
  }
}

@media (max-width: 1200px) {
  .projectInfo__textSection--imgBox__img {
    width: 80px;
    margin: 0 5px;
  }
}

@media (max-width: 992px) {
  .projectInfo__textSection--imgBox__img {
    width: 70px;
  }
}

@media (max-width: 768px) {
  .projectInfo__textSection--imgBox__img {
    width: 80px;
  }
}

@media (max-width: 576px) {
  .projectInfo__textSection--imgBox__img {
    width: 100px;
  }
}
/*# sourceMappingURL=projectinfo.css.map */
